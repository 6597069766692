
import baseComponent from "@/scripts/baseComponent";
import lazyLoadComponent from "@/scripts/lazyLoadComponent";
import SkeletonBox from '@/components/SkeletonBoxWithoutLoading';

export default {
    extends: baseComponent,
    data() { },
    components: {
        userActiveDetail: lazyLoadComponent({
            componentFactory: () => import("@/views/components/UserActiveView"),
            loading: SkeletonBox,
        }),
    }
}